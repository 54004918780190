<template>
  <button type="button" class="btn btn-secondary" @click="this.$emit('refresh')">Refresh</button>
  <table class="table table-striped table-hover ">
    <thead>
      <tr>
          <th scope="col">Id</th>
          <th scope="col">Network</th>
          <th scope="col">Status</th>
          <th scope="col">Start date</th>
          <th scope="col">Last Updated</th>
          <th scope="col">Duration</th>
          <th scope="col">Source</th>
          <th scope="col">Processed</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="result in results"
        :key="result.ID"
      >
          <td>{{ result.ID.substring(0, 5) }}</td>
          <td>{{ result.Request.type }}</td>
          <td>
            <img alt="In progress" src="../assets/tail-spin.svg" class="tail-spin" v-if="result.Status != 'complete' && result.Status != 'error'" />
            {{ result.Status }}
          </td>
          <td>{{ formatDate(result.StartTime) }}</td>
          <td>{{ formatDate(result.LastUpdated) }}</td>
          <td>{{ Number.parseFloat(result.TimeTaken / 1000000000).toFixed(2) + 's' }}</td>
          <td>{{ result.Source }}</td>
          <td>{{ countProcessed(result) }}</td>
          <td class="text-left">
            <button type="button" class="btn btn-info btn-sm mx-1" @click="viewModal('Request', result.Request)">Request</button>
            <button type="button" class="btn btn-warning btn-sm mx-1" @click="viewModal('Errors', result.Errors)" v-if="result.Errors && result.Errors.length > 0">Errors</button>
            <button type="button" class="btn btn-info btn-sm mx-1" @click="viewProcess(result)" v-if="result.ProcessResult && result.ProcessResult.length > 0">Processing</button>
            <button type="button" class="btn btn-info btn-sm mx-1" @click="viewRawDataModal(result)" v-if="result.OutputFiles && result.OutputFiles.length > 0">Raw data</button>
            <button type="button" class="btn btn-info btn-sm mx-1" @click="redoRequest(result.Request)">Redo</button>
            <button type="button" class="btn btn-info btn-sm mx-1" @click="reProcess(result.Request)" v-if="result.ProcessResult && result.ProcessResult.length > 0">Reprocess</button>
          </td>
      </tr>
    </tbody>
  </table>

  <nav aria-label="Navigation">
    <ul class="pagination">
      <li class="page-item"><a class="page-link" href="#" @click="prevPage" v-if="page > 1">Previous</a></li>
      <li class="page-item"><a class="page-link">{{ page }}</a></li>
      <li class="page-item"><a class="page-link" href="#" @click="nextPage" v-if="showNextPage()">Next</a></li>
    </ul>
  </nav>

  <transition name="modal">
      <modal v-if="showModal" @close="showModal=false">
          <template v-slot:title>{{ modalTitle }}</template>
          <template v-slot:body>
              <pre>
                  {{ modalBody }}
              </pre>
          </template>
      </modal>
  </transition>

  <process-modal v-if="showProcessModal" :processData="activeRecord.ProcessResult" @processClose="processClose" />
  <raw-data-modal v-if="showRawDataModal" :files="activeRecord.OutputFiles" @rawDataClose="rawDataClose" />

</template>

<script>
import Modal from './Modal.vue'
import ProcessModal from './ProcessModal.vue'
import RawDataModal from './RawDataModal.vue'
import { dateToString } from '../util/dates'
import Api from '../util/api'

export default {
  name: 'ImportResultList',
  components: {
    Modal,
    ProcessModal,
    RawDataModal
  },
  props: {
    results: Array,
    page: Number,
    totalResults: Number
  },
  data () {
    return {
      showModal: false,
      modalTitle: null,
      modalBody: null,
      showProcessModal: false,
      showRawDataModal: false,
      activeRecord: []
    }
  },
  methods: {
    viewModal: function (title, content) {
      this.showModal = true
      this.modalTitle = title
      this.modalBody = content
    },
    viewProcess: function (record) {
      this.activeRecord = record
      this.showProcessModal = true
    },
    processClose: function () {
      this.showProcessModal = false
    },
    viewRawDataModal: function (record) {
      this.activeRecord = record
      this.showRawDataModal = true
    },
    rawDataClose: function () {
      this.showRawDataModal = false
    },
    nextPage: function () {
      this.$emit('nextPage')
    },
    prevPage: function () {
      this.$emit('prevPage')
    },
    formatDate: function (value) {
      return dateToString(value)
    },
    showNextPage: function () {
      return ((this.page * 15) < this.totalResults)
    },
    countProcessed: function (result) {
      let processed = 0
      if (result.ProcessResult && result.ProcessResult.length > 0) {
        result.ProcessResult.forEach((process) => {
          processed = processed + process.SuccessfulRows
        })
      }
      return new Intl.NumberFormat('en-GB').format(processed)
    },
    reProcess: function (request) {
      if (confirm('Are you sure you want to reproces this download?')) {
        Api.reProcessResult(request.id).then(
          () => this.$emit('refresh')
        )
      }
    },
    redoRequest: function (oldRequest) {
      if (confirm('Are you sure you want to redo this (it will also redownload)?')) {
        const config = Object.assign({}, oldRequest)
        delete config.id
        delete config.type
        Api.runRequest({
          name: oldRequest.type,
          config
        }).then(() => this.$emit('refresh'))
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .tail-spin{
    height: 15px;
    width: 15px;
  }
</style>
