<template>
  <div id="nav">
    <router-link to="/">Transactions</router-link> |
    <router-link to="/currency">Currency</router-link> |
    <router-link to="/product">Product</router-link> |
    <router-link to="/ppc">PPC</router-link> |
    <router-link to="/costs">Costs</router-link> |
    <router-link to="/finance">Finance</router-link> |
    <router-link to="/ecommerce-orders">Ecommerce Orders</router-link> |
    <router-link to="/run">Run new import</router-link> |
    <router-link to="/schedule">Schedule Manager</router-link>
  </div>
  <router-view :key="$route.path" />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px 0;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1000px;
  margin: 0px auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
  position: absolute;
  top: 0px;
  right: 1rem;
}
</style>
