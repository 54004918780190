<template>
 <div class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-container">

        <div class="modal-body">
            <h3><slot name="title"></slot></h3>
            <slot name="body">
                default body
            </slot>
            <button class="modal-default-button" @click="$emit('close')">
                Close
            </button>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
