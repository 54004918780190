class Api {
  get (endpoint) {
    return fetch(endpoint, {
      method: 'get'
    })
      .catch(err => alert(err))
      .then(response => response.json())
  }

  post (endpoint, body) {
    return fetch(endpoint, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .catch(err => alert(err))
      .then(response => {
        if (!response.ok) throw response
        return response.json()
      })
  }

  getData (path) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}${path}`
    return this.get(endpoint)
  }

  getResults (body) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}results`
    return this.post(endpoint, body)
  }

  getSchedules (type = '') {
    let endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}schedules`
    if (type && type !== '') {
      endpoint += `?type=${type}`
    }
    return this.get(endpoint)
  }

  getSchedule (id) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}schedule/${id}`
    return this.get(endpoint)
  }

  removeSchedule (id) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}schedule/${id}`
    return fetch(endpoint, {
      method: 'DELETE'
    })
      .catch(err => alert(err))
      .then(response => response.json())
  }

  createSchedule (body) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}schedule`
    return this.post(endpoint, body)
  }

  saveSchedule (body) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}schedule`
    return fetch(endpoint, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .catch(err => alert(err))
      .then(response => response.json())
  }

  getDefinitions (type) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}import-definitions/${type}`
    return fetch(endpoint, {
      method: 'get',
      cache: 'force-cache'
    })
      .catch(err => alert(err))
      .then(response => response.json())
  }

  getTypes (type) {
    return this.getDefinitions(type)
      .then((data) => {
        const names = []
        data.forEach(element => {
          names.push(element.name)
        })
        return names
      })
  }

  runRequest (body) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}run`
    return this.post(endpoint, body)
  }

  reProcessResult (requestId) {
    const endpoint = `${process.env.VUE_APP_ENV_ENDPOINT}process/${requestId}`
    return this.post(endpoint)
  }
}

export default new Api()
