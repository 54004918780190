<template>
 <div class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-container">

        <div class="modal-body">
            <h3>Raw Data</h3>
            <ul>
              <li v-for="result in files" :key="result.ID"><a :href="getFileDownloadPath(result.FilePath)" target="_blank">{{result.FilePath}}</a></li>
            </ul>
            <button class="modal-default-button" @click="$emit('rawDataClose')">
                Close
            </button>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { dateToString } from '../util/dates'
export default {
  name: 'RawDataModal',
  props: {
    files: Array
  },
  methods: {
    getFileDownloadPath: function (file) {
      return `${process.env.VUE_APP_ENV_ENDPOINT}file/${file}`
    },
    formatDate: function (value) {
      return dateToString(value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
