import { DateTime } from 'luxon'

export function dateToString (value, format = 'yyyy/LL/dd HH:mm') {
  if (value) {
    const date = DateTime.fromISO(String(value))
    return date.toFormat(format)
  }
}

export function dateToISO (value, setEnd = false) {
  if (value) {
    const date = DateTime.fromFormat(String(value), 'yyyy-MM-dd', 'UTC')

    if (setEnd) {
      return date.endOf('day').toISO({ includeOffset: false }) + '+00:00'
    }
    return date.toISO({ includeOffset: false }) + '+00:00'
  }
}
