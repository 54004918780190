<template>
  <form class="row g-3" @submit.prevent="">
    <div class="col-auto">
      <select class="form-select" v-model="import_name">
        <option value="">Any network</option>
        <option v-for="option in importNames" v-bind:value="option" :key="option">
          {{ option }}
        </option>
      </select>
    </div>

    <div class="col-auto">
      <select class="form-select" v-model="import_status">
        <option value="">Any status</option>
        <option v-for="status in statuses" v-bind:value="status " :key="status">
          {{ status }}
        </option>
      </select>
    </div>

    <div class="col-auto">
      <input type="date" class="form-control" v-model="import_date" />
    </div>

    <div class="col-auto">
      <button type="submit" class="btn btn-primary mb-2" @click="getResults">Filter</button>
    </div>
    <div class="col-auto" v-if="isDirty()">
      <button class="btn btn-primary mb-2" @click="resetSearch">Reset</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'Search',
  props: {
    importNames: Array
  },
  data () {
    return {
      import_name: '',
      import_date: '',
      import_status: '',
      statuses: [
        'pending',
        'downloading',
        'downloaded',
        'processing',
        'complete',
        'error'
      ]
    }
  },
  methods: {
    isDirty: function () {
      return this.import_name !== '' || this.import_date !== '' || this.import_status !== ''
    },
    resetSearch: function () {
      this.import_name = ''
      this.import_date = ''
      this.import_status = ''
      this.getResults()
    },
    getResults: function () {
      this.$emit('setValue', 'network', this.import_name)
      this.$emit('setValue', 'import_date', this.import_date)
      this.$emit('setValue', 'import_status', this.import_status)
      this.$emit('loadData')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
