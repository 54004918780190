import { createRouter, createWebHistory } from 'vue-router'
import ImportList from '../views/ImportList.vue'

const routes = [
  {
    path: '/',
    name: 'transactions',
    component: ImportList,
    props: {
      importType: 'transaction'
    }
  },
  {
    path: '/currency',
    name: 'currency',
    component: ImportList,
    props: {
      importType: 'currency'
    }
  },
  {
    path: '/product',
    name: 'Products',
    component: ImportList,
    props: {
      importType: 'product'
    }
  },
  {
    path: '/ppc',
    name: 'PPC',
    component: ImportList,
    props: {
      importType: 'ppc'
    }
  },
  {
    path: '/costs',
    name: 'Service Costs',
    component: ImportList,
    props: {
      importType: 'service_cost'
    }
  },
  {
    path: '/finance',
    name: 'Finance',
    component: ImportList,
    props: {
      importType: 'finance'
    }
  },
  {
    path: '/ecommerce-orders',
    name: 'Ecommerce Orders',
    component: ImportList,
    props: {
      importType: 'ecommerce_order'
    }
  },
  {
    path: '/schedule',
    name: 'schedule_list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Schedule.vue')
  },
  {
    path: '/schedule/edit/:id',
    name: 'edit_schedule',
    props: {
      mode: 'edit'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditSchedule.vue')
  },
  {
    path: '/schedule/create',
    name: 'create_schedule',
    props: {
      mode: 'create'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditSchedule.vue')
  },
  {
    path: '/run',
    name: 'run_import',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Create.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
