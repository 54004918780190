<template>
  <Search
    :importNames="importNames"
    @getResults="getResults"
    @setValue="setValue"
    @loadData="loadData"
  />
  <ImportResultList
    :results="results"
    :page="page"
    :totalResults="totalResults"
    @nextPage="nextPage"
    @prevPage="prevPage"
    @refresh="refresh"
    @setValue="setValue"
  />
</template>

<script>
import ImportResultList from '../components/ImportResultList.vue'
import Search from '../components/Search.vue'
import Api from '../util/api'

export default {
  name: 'ImportList',
  props: {
    importType: String
  },
  components: {
    ImportResultList,
    Search
  },
  data () {
    return {
      page: 1,
      network: null,
      import_date: null,
      import_status: null,
      totalResults: 0,
      results: [],
      importNames: []
    }
  },
  mounted: function () {
    this.loadData()
    this.getTypes()
  },
  methods: {
    getTypes: function () {
      Api.getTypes(this.importType)
        .then(data => {
          this.importNames = data.sort()
        })
    },
    nextPage: function () {
      const page = this.page + 1
      this.setValue('page', page, true)
    },
    prevPage: function () {
      if (this.page > 1) {
        const page = this.page - 1
        this.setValue('page', page, true)
      }
    },
    refresh: function () {
      this.loadData()
    },
    setValue: function (name, value, refresh = false) {
      this[name] = value
      if (refresh) this.loadData()
    },
    loadData: function () {
      const body = {}
      body.import_type = this.importType
      body.import_date = this.import_date
      body.network = this.network
      body.page = this.page
      body.status = this.import_status

      const request = Object.fromEntries(Object.entries(body).filter(([_, v]) => (v != null && v !== '')))
      Api.getResults(request)
        .then(data => {
          this.results = data.Results
          this.totalResults = data.Count
        })
    }
  }
}
</script>

<style>
</style>
