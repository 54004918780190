<template>
 <div class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-container">

        <div class="modal-body">
            <h3>Process Data</h3>
            <table class="table table-striped">
              <thead>
                <tr>
                    <th scope="col">File</th>
                    <th scope="col">Row Count</th>
                    <th scope="col">Errored Rows</th>
                    <th scope="col">Status</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Errors</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="result in processData"
                  :key="result.ID"
                >
                  <td scope="col"><a :href="getFileDownloadPath(result.FilePath)" target="_blank">Download</a></td>
                  <td scope="col">{{ new Intl.NumberFormat('en-GB').format(result.NumRows) }}</td>
                  <td scope="col">{{ result.ErroredRows }}</td>
                  <td scope="col">{{ result.Status }}</td>
                  <td scope="col">{{ Number.parseFloat(result.TimeTaken / 1000000000).toFixed(2) + 's' }}</td>
                  <td scope="col">{{ result.Errors }}</td>
                </tr>
              </tbody>
            </table>
            <button class="modal-default-button" @click="$emit('processClose')">
                Close
            </button>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { dateToString } from '../util/dates'
export default {
  name: 'ProcessModal',
  props: {
    processData: Array
  },
  methods: {
    getFileDownloadPath: function (file) {
      return `${process.env.VUE_APP_ENV_ENDPOINT}file/${file}`
    },
    formatDate: function (value) {
      return dateToString(value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
